import './App.css';
import { useState, useEffect, useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { Button, DateRangePicker } from 'rsuite';
import {
  Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend
} from "chart.js";
import 'chartjs-adapter-date-fns';
import { fr } from 'date-fns/locale';
import { Line } from 'react-chartjs-2';
import startOfDay from 'date-fns/startOfDay';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import addDays from 'date-fns/addDays';
import subHours from 'date-fns/subHours';
import { db } from "./utils/firebase";

import { onValue, ref, child, get } from "firebase/database";


ChartJS.register(TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const getDates = (start, end) => {
  for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(new Date(dt));
  }
  return arr;
};

const addOffsetToDate = (date) => {
  const offset = date.getTimezoneOffset()
  return new Date(date.getTime() - (offset * 60 * 1000))
}


const App = () => {
  const [battery, setBattery] = useState([]);
  const [humidity, setHumidity] = useState([]);
  const [linkQuality, setLinkQuality] = useState([]);
  const [temperature, setTemperature] = useState([]);
  const [labels, setLabels] = useState([]);
  const [range, setRange] = useState([startOfDay(new Date()), new Date()]);
  const [showTech, setShowTech] = useState(false);

  const canvasRef = useRef(null)
  const rangeOff = [addOffsetToDate(range[0]), addOffsetToDate(range[1])];

  const { combine, allowedMaxDays, afterToday } = DateRangePicker;

  const raffraichir = () => {
    const allDatesBetween = getDates(rangeOff[0], rangeOff[1]).map((v) => v.toISOString().slice(0, 10))
    setBattery([])
    setHumidity([])
    setLinkQuality([])
    setTemperature([])
    setLabels([])
    for (const date of allDatesBetween) {
      let startHour = null
      let endHour = null
      if (date === allDatesBetween[0]) {
        startHour = rangeOff[0].toISOString().slice(11, 19)
      }
      if (date === allDatesBetween[1]) {
        endHour = rangeOff[1].toISOString().slice(11, 19)
      }
      const query = ref(db, "data_" + date);
      get(query).then((snapshot) => {
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val());
          let dataFiltered;
          if (startHour != null && endHour != null) {
            // Cas de la même journée
            dataFiltered = data.filter(el => el.periodLong.slice(11, 19) >= startHour && el.periodLong.slice(11, 19) <= endHour);
          } else if (startHour != null) {
            dataFiltered = data.filter(el => el.periodLong.slice(11, 19) >= startHour);
          } else if (endHour != null) {
            dataFiltered = data.filter(el => el.periodLong.slice(11, 19) <= endHour);
          } else {
            dataFiltered = data;
          }
          dataFiltered.forEach((event) => {
            setBattery((battery) => [...battery, event.msg.battery])
            setHumidity((humidity) => [...humidity, event.msg.humidity])
            setLinkQuality((linkQuality) => [...linkQuality, event.msg.linkquality])
            setTemperature((temperature) => [...temperature, event.msg.temperature])
            setLabels((labels) => [...labels, event.periodLong])
          });
        } else {
          console.warn("No data available.");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    // return onValue(query, (snapshot) => {
    //   const data = snapshot.val();
    //   if (snapshot.exists()) {

    //   }
    // });
  }

  const predefinedRanges = [
    {
      label: '2 heures',
      placement: 'left',
      value: [subHours(new Date(), 2), new Date()]
    },
    {
      label: '10 heures',
      placement: 'left',
      value: [subHours(new Date(), 10), new Date()]
    },
    {
      label: '24 heures',
      placement: 'left',
      value: [subHours(new Date(), 24), new Date()]
    },
    {
      label: 'Aujourd\'hui',
      placement: 'left',
      value: [startOfDay(new Date()), new Date()]
    },
    {
      label: 'Hier',
      placement: 'left',
      value: [startOfDay(addDays(new Date(), -1)), startOfDay(new Date())]
    },
    {
      label: 'Cette semaine',
      placement: 'left',
      value: [startOfWeek(new Date()), new Date()]
    },
    {
      label: '2 jours',
      placement: 'left',
      value: [startOfDay(subDays(new Date(), 1)), new Date()]
    },
    {
      label: '7 jours',
      placement: 'left',
      value: [startOfDay(subDays(new Date(), 6)), new Date()]
    }
  ];
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,

    plugins: {
      title: {
        display: true,
        color: 'white',
        text: 'Température et Humidité dans la maison'
      },
      legend: {
        position: 'bottom',
        labels: {
          color: 'white'
        }
      }
    },
    scales: {

      x: {
        type: 'time',

        time: {
          adapters: {
            date: {
              locale: fr
            }
          },
          parser: 'yyyy-MM-dd HH:mm:ss',
          // unit: "hour",
          // tooltipFormat: 'HH:mm',
          // displayFormats: {
          //   'seconds': "HH:mm:ss"
          // },
          // unitStepSize: 30
        },
        display: true,
        grid: {
          color: 'grey'
        },
        ticks: {
          color: 'white',
        }
      },

      y: {
        type: 'linear',
        display: true,
        position: 'left',
        grid: {
          color: 'grey'
        },
        ticks: {
          color: 'white'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',

        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        ticks: {
          color: 'white'
        }
      },
    }
  }
  const optionsTech = JSON.parse(JSON.stringify(options));
  optionsTech.plugins.title.text = "Données techniques"
  // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const data = {
    labels,
    datasets: [
      {
        label: 'Température',
        data: temperature,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
        pointRadius: 2,
      },
      {
        label: 'Humidité',
        data: humidity,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y1',
        pointRadius: 2,
      },
    ],
  };

  const dataTech = {
    labels: labels,
    datasets: [
      {
        label: 'Batterie',
        data: battery,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y',
        pointRadius: 2,
      },
      {
        label: 'Qualité du lien',
        data: linkQuality,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        yAxisID: 'y1',
        pointRadius: 2,
      },
    ],
  };

  useEffect(() => {
    raffraichir();
  }, [range]);

  return (
    <Container fluid>
      <Row className="mt-2">
        <Col style={{ color: 'white' }}>
          <p className='lead'> Monitoring de la température et de l'humidité. </p>
        </Col>
        <Col>
          Choix d'une plage de temps :
          <DateRangePicker
            showOneCalendar
            disabledDate={combine(allowedMaxDays(8), afterToday())}
            value={range} onChange={(e) => {
              if (e != null) {
                setRange(e)
              } else {
                setRange([new Date(), new Date()])
              }
            }}
            ranges={predefinedRanges}
            className="ms-2 mb-1"
            format="yyyy-MM-dd HH:mm"
          />
        </Col>
        <Col xs={2}><Button onClick={raffraichir}>Raffraichir</Button></Col>
      </Row>
      <Row className="mt-1"> <Col xs={2}><Button onClick={() => {setShowTech(!showTech)}}>Graphe technique</Button></Col> </Row>
      <Row className="mt-2 graphTd">
        <Col xs={0} lg={1}></Col>
        <Col xs={12} lg={10} className="graphContainer"> <Line ref={canvasRef} options={options} data={data} /></Col>
      </Row>
      {showTech ? <Row className="mt-3 pt-2 graphYtd">
        <Col xs={0} lg={1}></Col>
        <Col xs={12} lg={10} className="graphContainer"> <Line options={optionsTech} data={dataTech} /></Col>
      </Row> : null}

    </Container>
  );
}

export default App;
