import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "rsuite/dist/rsuite.min.css";
import { CustomProvider } from 'rsuite';
import fr_FR from 'rsuite/locales/fr_FR';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CustomProvider  theme="dark" locale={fr_FR}>
        <App />
    </CustomProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
