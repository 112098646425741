import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    databaseURL: "https://home-automation-9f412-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "home-automation-9f412",
  };


const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);